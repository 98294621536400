<template>
  <div class="container d-flex flex-column">
    <v-row no-gutters>
      <v-col align-self="center">
        <v-img
          min-height="200px"
          :src="require('@/assets/images/bread-bg2.jpg')"
        >
          <div class="text-center" style="padding-top: 2.5rem">
            <h1>Virhe, sivua ei löytynyt</h1>
            <v-btn :to="{ name: 'Home', path: '/' }">
              Takaisin Etusivulle
            </v-btn>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>